<template>
  <aside class="sidebar">
    <div class="logo">
      <img src="/img/logos/Logo_Bouwrisk.svg" width="70%" />
    </div>
    <div class="navigation">
      <a-menu class="desktop-navigation" mode="inline" v-model:selectedKeys="selectedKeys" @click="navigateTo">
        <a-menu-item key="Dashboard">
          <template #icon>
            <CameraOutlined />
          </template>
          Mijn opname
        </a-menu-item>
        <a-menu-item key="ContactDetails">
          <template #icon>
            <MailOutlined />
          </template>
          Mijn contactgegevens
        </a-menu-item>
        <a-menu-item key="Logout" @click.stop="authStore().logout()">
          <template #icon>
            <LogoutOutlined />
          </template>
          Uitloggen
        </a-menu-item>
      </a-menu>
    </div>
  </aside>
</template>

<script setup lang="ts">
import { onMounted, ref, watch } from 'vue'
import { useRoute, useRouter } from 'vue-router'
import { CameraOutlined, MailOutlined, LogoutOutlined } from '@ant-design/icons-vue'
import { authStore } from '@/stores/auth'

const selectedKeys = ref<string[]>([])
const route = useRoute()
const router = useRouter()

watch(() => route.name, () => {
  setActiveRoute()
})

onMounted(() => {
  setActiveRoute()
})

function setActiveRoute () : void {
  if (typeof route.name === 'string') {
    switch (route.name) {
      default:
        selectedKeys.value = [route.name]
        break
    }
  }
}

function navigateTo (navItem:{ key:string }) : void {
  if (route.name !== navItem.key && navItem.key !== 'Logout') {
    const keyboard = window.event as KeyboardEvent
    if (keyboard.ctrlKey) {
      const route = router.resolve({ name: navItem.key })
      window.open(route.href, '_blank')
    } else {
      router.push({ name: navItem.key })
    }
  }
}
</script>

<style lang="less">
.sidebar {
  grid-area: sidebar;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  height: 100vh;
  padding: 1rem 0;
  background-color: white;
  border-right: 1px solid #f0f0f0;
}

.logo {
  padding: 0 1rem;
  margin-bottom: 1rem;
}

.navigation {
  flex: 1;
  overflow-y: auto;

  .ant-menu-inline.desktop-navigation {
    border-right-color: transparent;
  }

  .ant-menu-inline.ant-menu-root .ant-menu-item > .ant-menu-title-content {
    margin-top: 4px;
  }
}
</style>
